<template>
  <div :class="['header',{'header--light' : light}]">
		<div class="header__container">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		light: Boolean,
	},
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
.header{
	background: var(--header-background);
	width: var(--header-width);
	height: var(--header-height);
	position: var(--header-position);
	box-shadow: var(--header-box-shadow);
	z-index: 8;
	display: flex;
	align-items: center;
	border: var(--header-border);
}
.header--light{
	background: var(--header-light-background);
}
.header__container{
	max-width: var(--header-container-width);
	width: 100%;
	margin: auto;
}

.page--layout-sidebar{
	.header{
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 1;
		grid-row-end: 2;
	}
}
</style>

<!-- Used in : AR, DS -->